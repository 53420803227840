<template>
  <b-jumbotron
    bg-variant="light"
    text-variant="black"
    mb="7"
    class="text-center pt-5"
  >
    <template slot="header">
      <span class="text-secondary">{{ statusCode }}</span>
    </template>
    <template slot="lead">
      <h2>Hoppsan!</h2>
      <h5 v-if="statusText">
        {{ decodeURIComponent(statusText) }}
      </h5>

      <div
        class="d-flex align-content-end flex-wrap justify-content-center"
        style="height: 58vh"
      >
        <font-awesome-icon icon="bug" style="color: #e0e0e0" spin size="sm" />
      </div>
    </template>
  </b-jumbotron>
</template>

<script>
export default {
  name: 'Error',
  computed: {
    statusCode() {
      return this.$route.params.statusCode ? parseInt(this.$route.params.statusCode) : '';
    },
    statusText() {
      return this.$route.params.statusText || 'Ett fel har inträffat.';
    }
  }
};
</script>
